import { graphql } from 'gatsby';
import { useStaticQuery } from 'gatsby';
import React from 'react';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import HeroWithPhotoAndHeader from '../../components/HeroWithPhotoAndHeader/heroWithPhotoAndHeader';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const GoldenAgeAlbumPage = () => {
  const { site } = useStaticQuery(
        graphql`
          query {
            site {
              siteMetadata {
                siteUrl
              }
            }
          }
        `,
    );

  return (
    <Layout backto="/albums" noheader notopmargin >
         <SEO lang="en" title={'The Golden Age by Woodkid'} keywords={['album', 'album review', 'Artyom Alekseev', 'Артём Алексеев', 'The Golden Age', 'Woodkid']} description="Woodkid's music suits a variety of situations, but most of all for escapism. But this escapism is not a deliberate attempt to escape reality, but a forced one.">
        </SEO>
        <HeroWithPhotoAndHeader backto="/albums" desc="A small post on the massive, complex Woodkid album." imageURL={'thegoldenage-woodkid.jpeg'} heading="The Golden Age by Woodkid"/>
        <Section >
            <Paragraph>Woodkid's music suits a variety of situations, but most of all for escapism. But this escapism is not a deliberate attempt to escape reality, but a forced one.</Paragraph>
            <Paragraph>Woodkid said in an interview that he wants to feel like heroes when listened to. And so it happens: the lyric orchestra, the low voice of Woodkid, simple but at the same time deep arrangements, clearly make you a participant in the chase, then take you to war, then talk about ordinary but important things, such as love.</Paragraph>
            <Paragraph>What amazes me more is not heroically, the sublimity of the songs, but what such an album can happen today. That even such a classical approach has found its place in contemporary music. It's funny that Woodkid himself directed clips of pop stars, that is, he was in an environment that was clearly not classical music.</Paragraph>
            <Paragraph>The main feature of our time is the ability to experiment. The Internet allows even the most experimental band to find fans. And this is the merit of time. Paradoxically, Woodkid himself sends us back in time, while realizing that he would not have been able to write this at any other time.</Paragraph>
            <Paragraph>This is the whole "The golden age". It is difficult, it does not always suit your mood, but if it suits you, then you will feel a burst of energy and can't stopю</Paragraph>
        </Section>

    </Layout>
  ); };

export default GoldenAgeAlbumPage;
